




































































































































































import { Vue, Component } from 'vue-property-decorator';
import { localize } from 'vee-validate';

import { appName } from '@/env';
import {
  commitSetDashboardShowDrawer,
  readDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
  readDashboardMiniDrawer,
  dispatchUserLogOut,
  readHasAdminAccess,
  commitSetLang,
} from '@/store/main/accessors';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public someKey: number = 0;
  public appName = appName;

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store));
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store));
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
  public async changeLocale() {
    this.$vuetify.lang.current = this.$vuetify.lang.current === 'ru' ? 'en' : 'ru';
    localStorage.lang = this.$vuetify.lang.current;
    commitSetLang(this.$store, this.$vuetify.lang.current);
    localize(this.$vuetify.lang.current);
    this.someKey += 1;
  }
}
